import PropTypes from 'prop-types'
import React from 'react'
import blackhole from '../images/blackhole.png'

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo">
      <img className="hole" src={blackhole} alt="hole" />

    </div>
    <div className="content">
      <div className="inner" style={{'font-size': '5vw'}}>
        <h1>Misanthropy.Space</h1>

      </div>
    </div>
    <nav>
            Coming Soon
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
